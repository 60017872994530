import { Link, NavLink } from 'react-router-dom';
import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import UserService from "../Services/UserService";
import PubSub from 'pubsub-js';
import { Tooltip } from 'react-tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import AuthService from '../Services/AuthService';


function UsersRegistered() {

    let [users, setUsers] = useState([]);
    let [filteredUsers, setFilteredUsers] = useState([]);
    let [searchParam, setSearchParam] = useState('');

    useEffect(() => {
        getUsersRegistered();

    }, []);

    useEffect(() => {
        if (searchParam) {
            let filteredUsers = users.filter(u =>
                u.name.toLowerCase().includes(searchParam.toLowerCase()) ||
                u.email.toLowerCase().includes(searchParam.toLowerCase()
                ));
            setFilteredUsers(filteredUsers);
        }
    }, [searchParam]);

    async function getUsersRegistered() {
        PubSub.publish('loading.notification', { message: 'Loading users...', show: true });
        let users = await UserService.getUsersRegistered();
        PubSub.publish('loading.notification', { message: 'Loading users...', show: false });
        setUsers(users);
    }

    async function revokeToken(email) {
        if (!window.confirm(`Are you sure you want to revoke the token for this user: ${email}?`)) {
            return;
        }

        let { message } = await AuthService.revokeTokenForEmail(email);
        PubSub.publish('show-basic-notification', {
            title: 'Token revoked',
            message: message
        });
    }

    function onClickOnSwitch(user) {
        user.paused = !user.paused;
        UserService.pauseUser(user._id, user.paused);
        setUsers([...users]);
        PubSub.publish('show-basic-notification', {
            title: 'User updated',
            message: `User has been ${user.paused ? 'paused' : 'unpaused'}`
        });
    }

    async function removeUser(user) {

        let confirmMessage = 'Are you sure you want to remove this user?, this action cannot be undone';

        if (!window.confirm(confirmMessage)) {
            return;
        }

        let { message } = await UserService.removeUser(user._id);
        setUsers(users.filter(u => u._id !== user._id));
        PubSub.publish('show-basic-notification', {
            title: 'User removed',
            message: message
        });
    }

    function renderAccessTokenIcon(access_token) {
        if (access_token) {
            return <i
                data-tooltip-id="users-registered-tooltip"
                data-tooltip-content="Access token available"
                class="fas fa-lock ms-2 text-primary"></i>
        }
    }

    function renderRefreshTokenIcon(refresh_token) {
        if (refresh_token) {
            return <i
                data-tooltip-id="users-registered-tooltip"
                data-tooltip-content="Refresh token available"
                class="fas fa-lock ms-2 text-primary"></i>
        }
    }


    function renderUsers() {

        let usersToRender = searchParam ? filteredUsers : users;

        return usersToRender.map((user, index) => (
            <div className="list-group" key={`user${index}`}>
                <div to={'/sequences/' + user._id}
                    className="sequence-list-item"
                    aria-current="true">

                    <Form.Check // prettier-ignore
                        type='checkbox'
                        id={`checkbox-${index}`}
                        className="checkbox me-3"
                        checked={user.selected}
                        onChange={() => {
                            user.selected = !user.selected;
                            setUsers([...users]);
                        }}
                        style={{ transform: 'scale(1.3)' }}
                    />


                    <Link to={'/admin/users/detail/' + user._id} className="w-100 pointer blank-link">
                        <div className="d-flex w-100 justify-content-between">
                            <p className="mb-1 bold fs-14">{user.name}
                                {user.campaigns.length > 0 && (
                                    <span
                                        data-tooltip-id="users-registered-tooltip"
                                        data-tooltip-content={user.campaigns.map(c => c.name).join(', ')}
                                        className='small text-danger ms-2'>{user.campaigns.length} campaigns active</span>
                                )}

                                {renderAccessTokenIcon(user.access_token)}
                                {renderRefreshTokenIcon(user.refresh_token)}

                            </p>
                        </div>
                        <small>
                            <span className="text-primary fs-12"> {user.email}</span>
                        </small>
                    </Link>

                    <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        className="mx-3"
                        onChange={() => onClickOnSwitch(user)}
                        checked={!user.paused}
                        style={{ transform: 'scale(1.3)' }}
                    />

                    <Dropdown>
                        <Dropdown.Toggle variant="muted"
                            className="no-chevron btn-sm" id="dropdown-basic">
                            <i className="fas fa-ellipsis-h"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => removeUser(user)}>Remove</Dropdown.Item>
                            <Dropdown.Item onClick={() => revokeToken(user.email)}>Revoke token</Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>



                </div>

            </div>
        ))
    }

    function renderEmptyUsers() {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <p className="fs-14">No users found</p>
            </div>
        )
    }

    return (
        <div>
            <div className="ulysses-sequences-title">
                <h5 className="bold">Users registered
                    (<small className='text-danger fs-14 mx-1'>{users.length}</small>)
                </h5>
            </div>

            <div className="ulysses-sequences-container">
                <div className="ulysses-sequences-body">
                    <div className="ulysses-sequences-buttons d-flex my-2 justify-content-between">
                        <NavLink
                            to={`/admin/users/new`}
                            className='btn btn-primary btn-sm'>
                            <i className="fas fa-user me-2"></i>
                            Create user
                        </NavLink>

                        <input type="text"
                            style={{ width: '200px' }}
                            className='form-control form-control-sm ms-2'
                            value={searchParam}
                            onChange={(e) => setSearchParam(e.target.value)}
                            placeholder='Search user' />
                    </div>


                    <div className="ulysses-sequences-table">
                        {renderUsers()}
                    </div>
                </div>
            </div>
            <Tooltip id="users-registered-tooltip" />
        </div>
    );
}

export default UsersRegistered;