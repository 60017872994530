import { useEffect, useState } from "react";
import AuthService from "../Services/AuthService";
import UserService from "../Services/UserService";
import { Modal } from 'bootstrap';
import PubSub from 'pubsub-js';
import ModalService from "../Services/ModalService";
import SelectIndustries from "../Modals/SelectIndustries";
import AppPasswordService from "../Services/AppPasswodService";
import { useNavigate } from "react-router-dom";



function Home() {

    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [user, setUser] = useState({});

    useEffect(() => {
        getCurrentUser();
    }, []);

    useEffect(() => {
        if (user && user?.name) {
            getUserIndustries();
            checkIfCurrentUserHasAppPassword();
        }
    }, [user])

    async function checkIfCurrentUserHasAppPassword() {
        let { data } = await AppPasswordService.currentUserHasAppPassword();
        if (!data) {
            navigate('/app-password/step2');
        }
    }

    const showModal = async () => {
        ModalService.openSelectIndustriesModal();
    }


    async function getUserIndustries() {
        let userIndustries = await UserService.getUserIndustries();
        if (userIndustries.industries.length === 0) {
            showModal();
        } else {
            setSelectedIndustries(userIndustries.industries);
        }
    }


    const hideIndustriesModal = async () => {
        var myModal = Modal.getInstance(document.getElementById('select-industries-modal'));
        myModal.hide();
    }



    const getCurrentUser = async () => {
        let user = await AuthService.getCurrentUser();
        setUser(user);
    }

    console.log('selectedIndustries', selectedIndustries);

    return (
        <div>

            <div className="container">
                <div className="mt-5"></div>
                <h3 className="bold mb-4">Let's get started!</h3>

                <p>Hi {user.name}, welcome to Ulysses Leads!</p>

                <button className="btn btn-transparent" onClick={showModal}>Select industries</button>


            </div>


            {user?.name && <SelectIndustries />} 


        </div>
    )

}

export default Home;