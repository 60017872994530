import { CURRENT_USER_HAS_APP_PASSWORD, SAVE_APP_PASSWORD, TEST_APP_PASSWORD } from "../constants";
import Requests from "../requests";

const AppPasswordService = {
    saveAppPassword,
    currentUserHasAppPassword,
    testAppPassword
}

function currentUserHasAppPassword() {
    return Requests.getWithAuth(CURRENT_USER_HAS_APP_PASSWORD);
}

function saveAppPassword({ account, password }) {
    return Requests.postWithAuth(SAVE_APP_PASSWORD, { account, password });
}

function testAppPassword({ account, password }) {
    return Requests.postWithAuth(TEST_APP_PASSWORD, { account, password });
}

export default AppPasswordService;