import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppPasswordService from '../Services/AppPasswodService';
import PubSub from 'pubsub-js';
import AuthService from '../Services/AuthService';

function AppPasswordStep3() {

    const navigate = useNavigate();

    const [user, setUser] = useState({});

    const [formData, setFormData] = useState({
        email: '',
        appPassword: ''
    });

    const [touched, setTouched] = useState({
        email: false,
        appPassword: false
    });

    const [errors, setErrors] = useState({
        email: '',
        appPassword: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) return 'Email is required';
        if (!emailRegex.test(email)) return 'Please enter a valid email address';
        return '';
    };

    const validateAppPassword = (password) => {
        // Count only non-space characters for validation
        const nonSpaceCharacters = password.replace(/\s/g, '');
        if (!nonSpaceCharacters) return 'App password is required';
        if (nonSpaceCharacters.length !== 16) return 'App password must be 16 characters';
        return '';
    };

    useEffect(() => {
        getCurrentUser();
    }, []);

    async function getCurrentUser() {
        let user = await AuthService.getCurrentUser();
        setUser(user);
        setFormData(prev => ({
            ...prev,
            email: user?.email
        }));
    }

    useEffect(() => {
        const emailError = validateEmail(formData.email);
        const appPasswordError = validateAppPassword(formData.appPassword);

        setErrors({
            email: touched.email ? emailError : '',
            appPassword: touched.appPassword ? appPasswordError : ''
        });
    }, [formData.email, formData.appPassword, touched]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleBlur = (field) => {
        setTouched(prev => ({
            ...prev,
            [field]: true
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Set all fields as touched to show validation errors
        setTouched({
            email: true,
            appPassword: true
        });

        // Only proceed if there are no errors
        if (!errors.email && !errors.appPassword) {
            try {
                setIsSubmitting(true);
                // Remove spaces from app password before submitting
                const cleanAppPassword = formData.appPassword.replace(/\s/g, '');

                // First check if app password is correct
                const { success: testSuccess, message: testMessage } = await AppPasswordService.testAppPassword({
                    account: formData.email,
                    password: cleanAppPassword
                });

                if (!testSuccess) {
                    PubSub.publish('show-basic-notification', {
                        title: 'Error',
                        message: testMessage
                    });
                    return;
                }


                const { success, message } = await AppPasswordService.saveAppPassword({
                    account: formData.email,
                    password: cleanAppPassword
                });

                if (!success) {
                    PubSub.publish('show-basic-notification', {
                        title: 'Error',
                        message: message
                    });
                    return;
                }


                PubSub.publish('show-basic-notification', {
                    title: 'success',
                    message: 'Account connected successfully'
                });

                navigate('/');


                // Handle successful connection (e.g., redirect or show success message)

            } catch (error) {
                console.error('Error connecting account:', error);
                // Handle error (e.g., show error message to user)
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <div className="min-vh-100 bg-light">
            <div className="container py-4">
                <div className="bg-white rounded-3 shadow-sm p-4 p-md-5" style={{ maxWidth: '500px', margin: '0 auto' }}>
                    <div className="text-center mb-4">
                        <div className="d-inline-block mb-3">
                            <img
                                src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png"
                                alt="Google Logo"
                                style={{ height: '40px' }}
                            />
                        </div>
                        <h2 className="h4 mb-2">Connect Your Google Account</h2>
                        <p className="text-muted mb-4">Gmail / G-Suite</p>
                    </div>

                    <form onSubmit={handleSubmit} noValidate>
                        <div className="mb-4">
                            <label className="form-label">
                                Email <span className="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                disabled
                                onBlur={() => handleBlur('email')}
                                placeholder="Email address to connect"
                                required
                            />
                            {touched.email && errors.email && (
                                <div className="invalid-feedback">
                                    {errors.email}
                                </div>
                            )}
                        </div>

                        <div className="mb-4">
                            <label className="form-label">
                                App Password <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className={`form-control ${touched.appPassword && errors.appPassword ? 'is-invalid' : ''}`}
                                name="appPassword"
                                value={formData.appPassword}
                                onChange={handleChange}
                                onBlur={() => handleBlur('appPassword')}
                                placeholder="Enter your 16 character app password"
                                required
                            />
                            {touched.appPassword && errors.appPassword && (
                                <div className="invalid-feedback">
                                    {errors.appPassword}
                                </div>
                            )}
                        </div>

                        <div className="d-flex gap-3 justify-content-between">
                            <Link
                                to="/app-password/step2"
                                className="btn btn-link text-decoration-none"
                            >
                                <i className="fas fa-chevron-left me-1"></i> Back
                            </Link>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <>
                                        <i className="fas fa-spinner fa-spin me-2"></i>
                                        Connecting...
                                    </>
                                ) : (
                                    <>
                                        Connect <i className="fas fa-chevron-right ms-1"></i>
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AppPasswordStep3;