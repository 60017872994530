import React from 'react';
import { Link } from 'react-router-dom';

function AppPasswordStep2() {
  return (
    <div className="min-vh-100 bg-light">
      <div className="container py-4">
        <div className="bg-white rounded-3 shadow-sm p-4 p-md-5" style={{ maxWidth: '500px', margin: '0 auto' }}>
          <div className="text-center mb-4">
            <div className="d-inline-block mb-3">
              <img
                src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png"
                alt="Google Logo"
                style={{ height: '40px' }}
              />
            </div>
            <h2 className="h4 mb-2">Connect Your Google Account</h2>
            <p className="text-muted mb-4">Gmail / G-Suite</p>

            <div className="text-start mb-4">
              <p className="mb-3">
                Enable 2-step verification & generate App password
              </p>
            </div>

            <div className="d-flex justify-content-center mb-4">
              <a target='_blank' 
              href='https://drive.google.com/file/d/1VyjBmaHVRSWTEHnW3VZF1cM-ELkep5pO/view' className="btn btn-outline-primary d-flex align-items-center gap-2">
                <i className="fas fa-play-circle"></i>
                See tutorial video
              </a>
            </div>

            <div className="border rounded-3 p-4 text-start mb-4">
              <ol className="list-unstyled mb-0">
                <li className="mb-4">
                  <div className="d-flex gap-3">
                    <span className="text-primary">1.</span>
                    <div>
                      Go to your Google Account's{' '}
                      <a href="https://myaccount.google.com/security" target="_blank" rel="noopener noreferrer" className="text-primary text-decoration-none">
                        Security Settings
                      </a>
                    </div>
                  </div>
                </li>
                <li className="mb-4">
                  <div className="d-flex gap-3">
                    <span className="text-primary">2.</span>
                    <div>
                      Enable{' '}
                      <a href="https://myaccount.google.com/signinoptions/two-step-verification" target="_blank" rel="noopener noreferrer" className="text-primary text-decoration-none">
                        2-step verification
                      </a>
                    </div>
                  </div>
                </li>
                <li className="mb-4">
                  <div className="d-flex gap-3">
                    <span className="text-primary">3.</span>
                    <div>
                      Create an{' '}
                      App password
                      <div className="text-muted mt-1">
                        Add 'Other' as the name of the App
                      </div>
                    </div>
                  </div>
                </li>
              </ol>
            </div>

            <div className="d-flex gap-3 justify-content-between">

              <Link
                to="/app-password/step3"
                className="btn btn-primary"
              >
                Next <i className="fas fa-chevron-right ms-1"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppPasswordStep2;