import { AUTH_SIGN_UP, GET_CURRENT_USER, GET_REGISTER_URL, GET_USER_FROM_CODE, REVOKE_TOKEN_BY_EMAIL, SIGN_IN_WITH_EMAIL, VALIDATE_IF_TOKEN_IS_VALID } from "../constants";
import Requests from "../requests";

const AuthService = {
    getRegisterUrl() {
        return Requests.get(GET_REGISTER_URL);
    },
    getUserFromCode(code) {
        return Requests.get(`${GET_USER_FROM_CODE}?code=${code}`);
    },
    saveAccessToken(token) {
        localStorage.setItem('access_token', token);
    },
    getAccessToken() {
        return localStorage.getItem('access_token');
    },
    validateIfTokenIsValid() {
        let token = localStorage.getItem('access_token');
        return Requests.get(`${VALIDATE_IF_TOKEN_IS_VALID}?access_token=${token}`);
    },
    signInWithEmail(email, password) {
        return Requests.post(SIGN_IN_WITH_EMAIL, { email, password });
    },
    signUp(email, password, name) {
        return Requests.post(AUTH_SIGN_UP, { email, password, name });
    },
    getCurrentUser() {
        return Requests.getWithAuth(`${GET_CURRENT_USER}`);
    },
    logout() {
        localStorage.removeItem('access_token');
    },
    revokeTokenForEmail(email) {
        return Requests.postWithAuth(REVOKE_TOKEN_BY_EMAIL, { email });
    }
}

export default AuthService;