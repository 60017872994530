export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export const GET_REGISTER_URL = `${API_URL}/api/google/url`;
export const GET_USER_FROM_CODE = `${API_URL}/api/google/code`;
export const VALIDATE_IF_TOKEN_IS_VALID = `${API_URL}/api/google/validate`;
export const GET_CURRENT_USER = `${API_URL}/api/google/user`;
export const REVOKE_TOKEN_BY_EMAIL = `${API_URL}/api/auth/token/revoke/email`;

export const SIGN_IN_WITH_EMAIL = `${API_URL}/api/auth/signin`;
export const AUTH_SIGN_UP = `${API_URL}/api/auth/signup`;
export const GET_USER_INDUSTRIES = `${API_URL}/api/user/industries`;
export const SAVE_USER_INDUSTRIES = `${API_URL}/api/user/industries/save`;

export const GET_LEADS = `${API_URL}/api/leads/get`;
export const UPLOAD_LEADS = `${API_URL}/api/leads/upload`;
export const GET_LEADS_IDS_AVAILABLE = `${API_URL}/api/leads/available/get`;
export const GET_LEADS_AVAILABLE_COUNT = `${API_URL}/api/leads/available/count`;
export const GET_LEAD_LISTS_NAMES = `${API_URL}/api/leads/list/names`;
export const SAVE_LEADS_INTO_LIST = `${API_URL}/api/leads/list/save`;

export const GET_SEQUENCE_TEMPLATES = `${API_URL}/api/templates/list`;

export const GET_SEQUENCES = `${API_URL}/api/sequences/list`;
export const GET_ADMIN_SEQUENCES = `${API_URL}/api/sequences/admin/list`;
export const GET_SEQUENCE_DETAIL = `${API_URL}/api/sequences/detail/:id`;
export const UPDATE_SEQUENCE = `${API_URL}/api/sequences/update/:id`;
export const RESET_SEQUENCE_START_DATE = `${API_URL}/api/sequences/reset/date`;
export const ASSIGN_LEADS_TO_SEQUENCE = `${API_URL}/api/email/sequence/leads/assign`;
export const ASSIGN_LEADS_TO_ALL_SEQUENCES = `${API_URL}/api/email/sequence/leads/all/assign`;
export const RESET_EMAIL_SEQUENCE_TO_SEQUENCE = `${API_URL}/api/email/sequence/leads/reset`;
export const ARCHIVE_SEQUENCE = `${API_URL}/api/sequences/archive/:id`;
export const GET_SEQUENCE_SETTINGS = `${API_URL}/api/sequences/settings/get/:id`;
export const UPDATE_SEQUENCE_SETTINGS = `${API_URL}/api/sequences/settings/update/:id`;

export const CREATE_SEQUENCE_FROM_TEMPLATE = `${API_URL}/api/sequences/template/create`;
export const UPDATE_SEQUENCE_STEP = `${API_URL}/api/sequences/step/update/:id`;

export const GET_SEQUENCES_LOGS = `${API_URL}/api/sequences/logs/:id`;
export const GET_SEQUENCES_COUNT = `${API_URL}/api/sequences/count`;
export const START_SEQUENCE_TIMER = `${API_URL}/api/email/sequence/start`;
export const STOP_SEQUENCE_TIMER = `${API_URL}/api/email/sequence/stop`;
export const GET_LATEST_RUNNING_SEQUENCE_EXECUTION = `${API_URL}/api/sequences/execution/latest/:id`;


export const GET_EMAIL_SEQUENCES_LOGS_BY_SEQUENCE_ID = `${API_URL}/api/email/sequence/logs/:id`;
export const GET_EMAIL_SEQUENCE_SUMMARY_BY_SEQUENCE_ID = `${API_URL}/api/email/sequence/summary/:id`;

export const CREATE_EMAIL_SEQUENCE_FROM_LEADS_IDSCREATE_EMAIL_SEQUENCE_FROM_LEADS_IDS = `${API_URL}/api/email/sequence/leads/create`;
export const CREATE_EMAIL_SEQUENCE_FROM_LEADS_IDS = `${API_URL}/api/email/sequence/leads/create`;
export const CREATE_EMAIL_SEQUENCE_FROM_LEADS_IDS_PLACEHOLDER = `${API_URL}/api/email/sequence/leads/placeholder`;
export const REMOVE_EMAIL_SEQUENCES_LEADS_IDS = `${API_URL}/api/email/sequence/leads/remove`;
export const GET_EMAIL_SEQUENCES_BY_SEQUENCE_ID = `${API_URL}/api/email/sequence/leads/:id`;

export const GET_USER_PROFILE = `${API_URL}/api/user/profile/get`;
export const GET_USER_PROFILE_BY_ID = `${API_URL}/api/user/admin/profile/get/:id`;
export const GET_USERS_REGISTERED = `${API_URL}/api/user/registered`;
export const GET_USER_BY_ID = `${API_URL}/api/user/admin/detail/:id`;
export const UPDATE_USER_BY_ID = `${API_URL}/api/user/admin/update/:id`;
export const UPDATE_USER_PROFILE = `${API_URL}/api/user/profile/update`;
export const UPDATE_USER_PROFILE_BY_ID = `${API_URL}/api/user/admin/profile/update/:id`;
export const PAUSE_USER = `${API_URL}/api/user/pause/:id`;
export const CREATE_USER_FROM_ADMIN = `${API_URL}/api/user/admin/create`;
export const REMOVE_USER = `${API_URL}/api/user/admin/remove/:id`;

export const INBOX_GET_THREAD_MESSAGES = `${API_URL}/api/inbox/threads/get`;
export const INBOX_GET_THREAD_COUNT = `${API_URL}/api/inbox/threads/count`;
export const GET_MESSAGES_BY_THREAD_ID = `${API_URL}/api/inbox/threads/detail/:id`;
export const GET_ACCOUNTS_WITH_INBOX = `${API_URL}/api/inbox/threads/accounts`;
export const GET_ACCOUNTS_IN_INBOX_WITH_NAMES = `${API_URL}/api/inbox/threads/accounts/name`;
export const SEND_EMAIL_REPLY = `${API_URL}/api/inbox/emails/reply/send`;
export const SEND_EMAIL_COMPOSE = `${API_URL}/api/inbox/emails/email/send`;
export const SYNC_THREADS_FROM_GMAIL = `${API_URL}/api/inbox/threads/sync`;
export const SYNC_THREADS_FROM_GMAIL_BY_ACCOUNT = `${API_URL}/api/inbox/threads/sync/account`;

export const UTILS_SEND_TEST_EMAIL = `${API_URL}/api/utils/email/send/test`;
export const SAVE_APP_PASSWORD = `${API_URL}/api/app-password/save`;
export const TEST_APP_PASSWORD = `${API_URL}/api/app-password/test`;
export const CURRENT_USER_HAS_APP_PASSWORD = `${API_URL}/api/app-password/user/has`;

export const GET_TEMPLATES_LIST = `${API_URL}/api/admin/templates/list`;
export const GET_TEMPLATE_BY_ID = (_id) => `${API_URL}/api/admin/templates/detail?_id=${_id}`;
export const UPDATE_TEMPLATE_STEP_BY_ID = (_id) => `${API_URL}/api/admin/templates/step/update?step_id=${_id}`;


export const MODAL_SAVE_LEADS = 'save-leads-modal';
export const MODAL_INBOX_COMPOSE = 'inbox-compose-container';
export const MODAL_NEW_SEQUENCE = 'new-sequence-modal';
export const MODAL_PREBUILT_TEMPLATES = 'prebuilt-sequences-modal';
export const MODAL_SELECT_INDUSTRIES = 'select-industries-modal';

export const EDITOR_BUTTONS = [
    ['viewHTML'],
    ['undo', 'redo'],
    'btnGrp-semantic',
    ['link'],
    ['insertImage'],
    ['foreColor', 'backColor'],
    'btnGrp-justify',
    'btnGrp-lists',
    ['table'],
    ['removeformat'],
    ['fullscreen']
];

export const DEFAULT_VARIABLES_FOR_PREVIEW = {
    lead_first_name: 'Jeb',
    company: 'Google',
    job_title: 'Software Engineer',
    industry: 'Software',
    location: 'San Francisco',
    email: 'johnmiles@gmail.com',
    requested_work: 'Software Engineer',
    country: 'Venezuela',
    city: 'Caracas',
    background: 'Software Engineer',
    name: 'John Deere',
    profile_url: 'linkedin.com/johntestdeere',
}