import React from 'react';
import { Link } from 'react-router-dom';

function AppPasswordStep1() {
  return (
    <div className="min-vh-100 bg-light">
      <div className="container py-4">
        <div className="bg-white rounded-3 shadow-sm p-4 p-md-5" style={{ maxWidth: '500px', margin: '0 auto' }}>
          <div className="text-center mb-4">
            <div className="d-inline-block mb-3">
              <img 
                src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png" 
                alt="Google Logo" 
                style={{ height: '40px' }}
              />
            </div>
            <h2 className="h4 mb-2">Connect Your Google Account</h2>
            <p className="text-muted mb-4">Gmail / G-Suite</p>

            <div className="text-start mb-4">
              <p className="mb-3">
                First, let's <a href="#" className="text-primary text-decoration-none">enable IMAP</a> access
                for your Google account.
              </p>
            </div>

            <div className="border rounded-3 p-4 text-start mb-4">
              <ol className="list-unstyled mb-0">
                <li className="mb-3">
                  <div className="d-flex gap-3">
                    <span className="text-primary">1.</span>
                    <span>On your computer, open Gmail.</span>
                  </div>
                </li>
                <li className="mb-3">
                  <div className="d-flex gap-3">
                    <span className="text-primary">2.</span>
                    <span>Click the <i className="fas fa-cog"></i> gear icon in the top right corner.</span>
                  </div>
                </li>
                <li className="mb-3">
                  <div className="d-flex gap-3">
                    <span className="text-primary">3.</span>
                    <span>Click <strong>All Settings</strong>.</span>
                  </div>
                </li>
                <li className="mb-3">
                  <div className="d-flex gap-3">
                    <span className="text-primary">4.</span>
                    <span>Click the <strong>Forwarding and POP/IMAP</strong> tab.</span>
                  </div>
                </li>
                <li className="mb-3">
                  <div className="d-flex gap-3">
                    <span className="text-primary">5.</span>
                    <span>In the "IMAP access" section, select <strong>Enable IMAP</strong>.</span>
                  </div>
                </li>
                <li className="mb-3">
                  <div className="d-flex gap-3">
                    <span className="text-primary">6.</span>
                    <span>Click <strong>Save Changes</strong>.</span>
                  </div>
                </li>
              </ol>
            </div>

            <div className="d-flex flex-column gap-3">
              <button className="btn btn-link text-decoration-none">
                Show me how <i className="fas fa-chevron-right ms-1"></i>
              </button>
              <Link 
                to="/app-password/step2"
                className="btn btn-primary"
              >
                Yes, IMAP has been enabled <i className="fas fa-chevron-right ms-1"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppPasswordStep1;