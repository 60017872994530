import React, { useState, useEffect } from 'react';
import AuthService from '../Services/AuthService';
import PubSub from 'pubsub-js';
import { Link, useNavigate } from 'react-router-dom';


function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [touched, setTouched] = useState({ email: false, password: false });
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) return 'Email is required';
    if (!emailRegex.test(email)) return 'Please enter a valid email address';
    return '';
  }

  function validatePassword(password) {
    if (!password) return 'Password is required';
    return '';
  }

  useEffect(function () {
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);

    setErrors({
      email: touched.email ? emailError : '',
      password: touched.password ? passwordError : ''
    });

    setIsValid(!emailError && !passwordError && email !== '' && password !== '');
  }, [email, password, touched]);

  async function onSignIn({ email, password }) {
    try {
      setIsLoading(true);

      const response = await AuthService.signInWithEmail(email, password);

      if (!response.success) {
        PubSub.publish('show-basic-notification', {
          title: 'Sign in error',
          message: response.message
        });
        return;
      }

      console.log('Sign in successful:', response);

      PubSub.publish('show-basic-notification', {
        title: 'Sign in successful',
        message: response.message
      });

      PubSub.publish('login.success');

      AuthService.saveAccessToken(response.data.token);
      // Handle successful sign in here (e.g., store token, redirect user)

      navigate('/');
    } catch (error) {
      console.error('Sign in error:', error);
      // Handle error here (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (isValid) {
      await onSignIn({ email, password });
    }
  }

  function handleBlur(field) {
    setTouched(prev => ({ ...prev, [field]: true }));
  }

  return (
    <div className="min-vh-100 d-flex align-items-center justify-content-center" style={{ backgroundColor: '#B3DFFF', marginTop: "-30px" }}>
      <div className="bg-white p-4 p-md-5 rounded-3 shadow-lg" style={{ maxWidth: '400px', width: '100%' }}>
        <div className="text-center mb-4">
          <div className="bg-light rounded-circle d-flex align-items-center justify-content-center mx-auto mb-3" style={{ width: '64px', height: '64px' }}>
            <i className="fas fa-user fa-2x text-primary"></i>
          </div>
          <h2 className="fw-bold">Welcome Back</h2>
          <p className="text-muted">Please sign in to your account</p>
        </div>

        <form onSubmit={handleSubmit} noValidate>
          <div className="mb-3">
            <label className="form-label">Email Address</label>
            <div className="input-group">
              <span className="input-group-text">
                <i className="fas fa-envelope"></i>
              </span>
              <input
                type="email"
                className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => handleBlur('email')}
                placeholder="Enter your email"
                required
              />
              {touched.email && errors.email && (
                <div className="invalid-feedback">
                  {errors.email}
                </div>
              )}
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Password</label>
            <div className="input-group">
              <span className="input-group-text">
                <i className="fas fa-lock"></i>
              </span>
              <input
                type={showPassword ? "text" : "password"}
                className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => handleBlur('password')}
                placeholder="Enter your password"
                required
              />
              <button
                type="button"
                className="input-group-text"
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: 'pointer' }}
              >
                <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
              </button>
              {touched.password && errors.password && (
                <div className="invalid-feedback">
                  {errors.password}
                </div>
              )}
            </div>
          </div>

          <div className="mb-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="remember-me"
              />
              <label className="form-check-label" htmlFor="remember-me">
                Remember me
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-dark w-100 py-2 mb-3"
            disabled={!isValid || isLoading}
          >
            {isLoading ? (
              <span>
                <i className="fas fa-spinner fa-spin me-2"></i>
                Signing in...
              </span>
            ) : (
              'Sign in'
            )}
          </button>

          <div className="text-center">
            <p className="text-muted mb-0">
              Don't have an account?{' '}
              <Link to="/signup" className="text-decoration-none">Sign up</Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignIn;