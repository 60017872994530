import React, { useState, useEffect } from 'react';
import AuthService from '../Services/AuthService';
import { Link, useNavigate } from 'react-router-dom';
import PubSub from 'pubsub-js';

function SignUp() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [touched, setTouched] = useState({
    name: false,
    email: false,
    password: false,
    confirmPassword: false,
    acceptTerms: false
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptTerms: ''
  });

  const [isValid, setIsValid] = useState(false);

  function validateName(name) {
    if (!name) return 'Name is required';
    if (name.length < 2) return 'Name must be at least 2 characters long';
    return '';
  }

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) return 'Email is required';
    if (!emailRegex.test(email)) return 'Please enter a valid email address';
    return '';
  }

  function validatePassword(password) {
    if (!password) return 'Password is required';
    if (password.length < 8) return 'Password must be at least 8 characters long';
    if (!/\d/.test(password)) return 'Password must contain at least one number';
    if (!/[a-z]/.test(password)) return 'Password must contain at least one lowercase letter';
    if (!/[A-Z]/.test(password)) return 'Password must contain at least one uppercase letter';
    return '';
  }

  function validateConfirmPassword(confirmPassword) {
    if (!confirmPassword) return 'Please confirm your password';
    if (confirmPassword !== password) return 'Passwords do not match';
    return '';
  }

  function validateAcceptTerms(accepted) {
    if (!accepted) return 'You must accept the terms and conditions';
    return '';
  }

  useEffect(function () {
    const nameError = validateName(name);
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(confirmPassword);
    const acceptTermsError = validateAcceptTerms(acceptTerms);

    setErrors({
      name: touched.name ? nameError : '',
      email: touched.email ? emailError : '',
      password: touched.password ? passwordError : '',
      confirmPassword: touched.confirmPassword ? confirmPasswordError : '',
      acceptTerms: touched.acceptTerms ? acceptTermsError : ''
    });

    setIsValid(
      !nameError &&
      !emailError &&
      !passwordError &&
      !confirmPasswordError &&
      !acceptTermsError &&
      acceptTerms
    );
  }, [name, email, password, confirmPassword, acceptTerms, touched]);

  async function onSignUp({ email, name, password }) {
    try {
      setIsLoading(true);

      const response = await AuthService.signUp(email, password, name)

      if (!response.success) {

        PubSub.publish('show-basic-notification', {
          title: 'Error',
          message: response.message
        });

        return;
      }

      console.log('Sign up successful:', response);

      PubSub.publish('show-basic-notification', {
        title: 'Success',
        message: response.message
      });
      let data = response.data;

      AuthService.saveAccessToken(data.token);
      // Handle successful sign up here (e.g., redirect to sign in);

      // Redirect to sign in
      navigate('/');

    } catch (error) {
      console.error('Sign up error:', error);
      // Handle error here (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (isValid) {
      await onSignUp({ name, email, password });
    }
  }

  function handleBlur(field) {
    setTouched(prev => ({ ...prev, [field]: true }));
  }

  return (
    <div className="min-vh-100 d-flex align-items-center justify-content-center" style={{ backgroundColor: '#B3DFFF', marginTop: "-40px" }}>
      <div className="bg-white p-2 p-md-5 pt-md-3 pb-md-3 rounded-3 shadow-lg" style={{ maxWidth: '500px', width: '100%' }}>
        <div className="text-center mb-2">
          <div className="bg-light rounded-circle d-flex align-items-center justify-content-center mx-auto mb-2" style={{ width: '64px', height: '64px' }}>
            <i className="fas fa-user-plus fa-2x text-primary"></i>
          </div>
          <h3 className="fw-bold">Create Account</h3>
        </div>

        <form onSubmit={handleSubmit} noValidate>
          <div className="mb-3">
            <label className="form-label">Full Name</label>
            <div className="input-group">
              <span className="input-group-text">
                <i className="fas fa-user"></i>
              </span>
              <input
                type="text"
                className={`form-control ${touched.name && errors.name ? 'is-invalid' : ''}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={() => handleBlur('name')}
                placeholder="Enter your full name"
                required
              />
              {touched.name && errors.name && (
                <div className="invalid-feedback">
                  {errors.name}
                </div>
              )}
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Email Address</label>
            <div className="input-group">
              <span className="input-group-text">
                <i className="fas fa-envelope"></i>
              </span>
              <input
                type="email"
                className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => handleBlur('email')}
                placeholder="Enter your email"
                required
              />
              {touched.email && errors.email && (
                <div className="invalid-feedback">
                  {errors.email}
                </div>
              )}
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Password</label>
            <div className="input-group">
              <span className="input-group-text">
                <i className="fas fa-lock"></i>
              </span>
              <input
                type={showPassword ? "text" : "password"}
                className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => handleBlur('password')}
                placeholder="Create a password"
                required
              />
              <button
                type="button"
                className="input-group-text"
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: 'pointer' }}
              >
                <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
              </button>
              {touched.password && errors.password && (
                <div className="invalid-feedback">
                  {errors.password}
                </div>
              )}
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Confirm Password</label>
            <div className="input-group">
              <span className="input-group-text">
                <i className="fas fa-lock"></i>
              </span>
              <input
                type={showConfirmPassword ? "text" : "password"}
                className={`form-control ${touched.confirmPassword && errors.confirmPassword ? 'is-invalid' : ''}`}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={() => handleBlur('confirmPassword')}
                placeholder="Confirm your password"
                required
              />
              <button
                type="button"
                className="input-group-text"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                style={{ cursor: 'pointer' }}
              >
                <i className={`fas ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
              </button>
              {touched.confirmPassword && errors.confirmPassword && (
                <div className="invalid-feedback">
                  {errors.confirmPassword}
                </div>
              )}
            </div>
          </div>

          <div className="mb-3">
            <div className="form-check">
              <input
                type="checkbox"
                className={`form-check-input ${touched.acceptTerms && errors.acceptTerms ? 'is-invalid' : ''}`}
                id="accept-terms"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
                onBlur={() => handleBlur('acceptTerms')}
              />
              <label className="form-check-label" htmlFor="accept-terms">
                I accept the <a href="https://ulyssesleads.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                  Terms and Conditions</a>
                  {' '} and <a href="https://ulyssesleads.com/privacy-policy/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                  Privacy Policy</a>
              </label>
              {touched.acceptTerms && errors.acceptTerms && (
                <div className="invalid-feedback">
                  {errors.acceptTerms}
                </div>
              )}
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-dark w-100 py-2 mb-3"
            disabled={!isValid || isLoading}
          >
            {isLoading ? (
              <span>
                <i className="fas fa-spinner fa-spin me-2"></i>
                Creating account...
              </span>
            ) : (
              'Sign up'
            )}
          </button>

          <div className="text-center">
            <p className="text-muted mb-0">
              Already have an account?{' '}
              <Link to="/signin" className="text-primary text-decoration-none">Sign in</Link>
            </p>
          </div>
        </form >
      </div >
    </div >
  );
}

export default SignUp;